<template>
  <div class="px-4">
    <div class="mt-3">
      <v-row >
        <v-col class="d-flex justify-center " cols="12">
          <v-btn
          class="mr-2"
          @click="administrativeForm = true, expressionForm = false"
          >
            Administrative Form
          </v-btn>
          <v-btn
          @click="expressionForm = true, administrativeForm = false"
          >
            Expression Of Intrest Form
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-form class="mt-3" v-if="administrativeForm">
      <v-card  class="pa-4 mx-auto">
        <h2 class="secondary pa-3">ADMINISTRATIVE FORM</h2>
        <!-- <div class="mt-3">
          <b>ID:</b>
          <v-text-field v-model.trim="dform.ID" outlined dense></v-text-field>
        </div> -->
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <b>Type Of Meeting:</b>
            <v-select
              :items="meetings"
              v-model.trim="dform.MeetingType"
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Meeting:</b>
            <v-text-field
              v-model.number="dform.Meeting"
              type="number"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Date:</b>
            <v-text-field
              v-model.trim="dform.Date"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>No Of Attendee:</b>
            <v-text-field
              v-model.number="dform.Attendee"
              type="number"
              outlined
              dense
            ></v-text-field>
          </v-col>
         
        </v-row>
        <p v-if="dform.id" class="text-end">
          <v-btn :loading="loading" @click="saveChanges" color="success"
            >Save Changes</v-btn
          >
        </p>
        <p v-else class="text-end">
          <v-btn :loading="loading" @click="post" color="success">Submit</v-btn>
        </p>
      </v-card>
    </v-form>
    <v-form class="mt-3" v-if="expressionForm">
      <v-card v-if="expressionForm" class="pa-4 mx-auto">
        <h2 class="secondary pa-3">Expression of Intrest Form</h2>
        <!-- <div class="mt-3">
          <b>ID:</b>
          <v-text-field v-model.trim="dform.ID" outlined dense></v-text-field>
        </div> -->
        <v-row>
          

          <v-col cols="12" sm="6" md="4">
            <b>Community Name</b>
            <v-text-field
              v-model.number="dform2.CommunityName"
              type="text"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Date of submission</b>
            <v-text-field
              v-model.trim="dform2.DateSubmitted"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>
         
        </v-row>
        <p v-if="dform.id" class="text-end">
          <v-btn :loading="loading" @click="saveChanges" color="success"
            >Save Changes</v-btn
          >
        </p>
        <p v-if="!dform.id && administrativeForm" class="text-end">
          <v-btn :loading="loading" @click="post" color="success">Submit</v-btn>
        </p>


        <p v-if="dform2.id" class="text-end">
          <v-btn :loading="loading" @click="saveFinance" color="success"
            >Save Changes</v-btn
          >
        </p>
        <p v-if="!dform2.id && expressionForm" class="text-end">
          <v-btn :loading="loading" @click="postFinance" color="success">Submit</v-btn>
        </p>


      </v-card>
    </v-form>
    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin, editUserMixin, editExpressMixin } from "@/mixins";
import { mapState, mapGetters } from "vuex";
export default {
  name: "Administrative",
  mixins: [snackMixin, editUserMixin, editExpressMixin],

 

  
  data() {
    return {
      administrativeForm: true,
      expressionForm: false,
      dform: this.initializeForm(),
      dform2: this.initializeForm2(),
    };
  },
  computed: {
    ...mapState(["meetings"]),
    ...mapGetters(["getCurUser"]),
  },
  methods: {
    initializeForm() {
      return {
        id: "",
        MeetingType: "",
        Meeting: 0,
        Date: "",
        Attendee: 0,
        created_by: "",
        modified_by: "",
      };

    },
    initializeForm2() {
      return {
        id: "",
        CommunityName: "",
        DateSubmitted: "",
        created_by: "",
        modified_by: "",
      };

    },

    post() {
      this.loading = true;
      this.dform.created_by = this.dform.modified_by = this.getCurUser;
      apiClient
        .post("/administrative", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.dform = res.data;
            this.dform = this.initializeForm();
            this.loading = false;
            this.displayMsg("New administrative created successfully");
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
        });
    },

    saveChanges() {
      this.dform.type = "administrative";
      this.loading = true;
      this.dform.modified_by = this.getCurUser;
      
      apiClient
        .put("/administrative", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.displayMsg("Changes saved successfully.Redirecting ...");
            this.loading = false;
            setTimeout(() => {
              this.$router.push({
                name: "AdministrativeTable",
              });
            }, 3000);
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
          this.loading = false;
        });
    },

    postFinance() {
      this.loading = true;
      this.dform2.created_by = this.dform2.modified_by = this.getCurUser;
      apiClient
        .post("/expression", this.dform2)
        .then((res) => {
          if (res.data.ok) {
            this.dform2 = res.data;
            this.dform2 = this.initializeForm2();
            this.loading = false;
            this.displayMsg("New Expression Form created successfully");
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
        });
    },

    saveFinance() {
      this.dform2.type = "expression";
      this.loading = true;
      this.dform2.modified_by = this.getCurUser;

      apiClient
        .put("/expression", this.dform2)
        .then((res) => {
          if (res.data.ok) {
            this.displayMsg("Changes saved successfully.Redirecting ...");
            this.loading = false;
            setTimeout(() => {
              this.$router.push({
                name: "AdministrativeTable",
              });
            }, 1000);
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
          this.loading = false;
        });
    },
  },
};
</script>
